import 'twin.macro'

import {FullPageCentered} from '../layout'
import {Logo} from '../logo'

/**
 * App-level loading indicator
 */
function FullPageLogo() {
  return (
    <FullPageCentered>
      <Logo tw="animate-pulse" />
    </FullPageCentered>
  )
}

export {FullPageLogo}
