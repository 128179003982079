import 'twin.macro'

import {Spinner} from './spinner'

function PageSpinner() {
  return (
    <div tw="flex items-center justify-center py-4">
      <Spinner tw="" />
    </div>
  )
}

export {PageSpinner}
