import {
  FetchQueryOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query'

import {camelcase} from '@/client/core/utils/camelcase'
import {getCurrentUserQueryKey} from '@/client/core/utils/query-keys'
import type {CurrentUser, StoredCurrentUser} from '@/types'

import {useUser} from '../context'

import {supabase} from './supabase-client'

// ------------------------------
// DB client
// ------------------------------

async function getUser(userId: string) {
  const {data: users, error} = await supabase
    .from<StoredCurrentUser>('users')
    .select(`*, teams!team_members(*), team_members(*)`)
    .eq('id', userId)
    .limit(1)
  if (error) throw error
  if (!users || !users[0]) return null
  return camelcase<CurrentUser>(users[0], [
    {key: 'teams', type: 'array'},
    {key: 'team_members', type: 'array'},
  ])
}

// ------------------------------
// React query
// ------------------------------

function useCurrentUser(options?: UseQueryOptions<CurrentUser | null, Error>) {
  const {user} = useUser()
  return useQuery<CurrentUser | null, Error>(
    getCurrentUserQueryKey(),
    () => {
      if (!user?.id) throw new Error(`Please add userId to the enabled field.`)
      return getUser(user.id)
    },
    {enabled: !!user?.id, ...options},
  )
}

function useGetOrFetchUser(
  options?: FetchQueryOptions<CurrentUser | null, Error>,
) {
  const queryClient = useQueryClient()
  return (userId: string) =>
    queryClient.fetchQuery<CurrentUser | null, Error>(
      getCurrentUserQueryKey(),
      () => getUser(userId),
      options,
    )
}

export {getUser, useCurrentUser, useGetOrFetchUser}
