import {useMutation, useQuery, useQueryClient} from 'react-query'
import {addDays, isPast, parseISO} from 'date-fns'

import {client} from '@/client/core/utils/api-client'
import {
  getBilliableTeamMembersQueryKey,
  getTeamQueryKey,
  getTeamSubscriptionQueryKey,
} from '@/client/core/utils/query-keys'
import type {CurrentUser, StoredPrice, StoredTeamMember, Team} from '@/types'

import 'twin.macro'

import {supabase} from './supabase-client'

const GRACE_PERIOD = 1

function getSubscriptionStatus(team: Team) {
  // ...when newly created, they are unpaid
  if (!team.currentPeriodEnd) return 'unpaid'
  // ...when they are subscribed, they must have current period end
  const currentPeriodEnd = parseISO(team.currentPeriodEnd)
  // ...and their subscription is active during the period
  if (!isPast(currentPeriodEnd)) return 'active'
  // ...when their current period ends, we give them a grace period to pay their invoices
  if (!isPast(addDays(currentPeriodEnd, GRACE_PERIOD))) {
    // ...except explicitly cancelled teams
    if (team.cancelAtPeriodEnd) return 'unpaid'
    // ...and manual teams
    if (getPaidSubscriptionType(team) === 'manual') return 'unpaid'
    return 'past_due'
  }
  // ...otherwise their subscription is unpaid
  return 'unpaid'
}

/**
 * If null, teams are not paid yet
 */
function getPaidSubscriptionType(team: Team) {
  const isManualPayment = team.memberLimit !== null && team.currentPeriodEnd
  const isRecurringPayment = team.memberLimit === null && team.currentPeriodEnd
  return isManualPayment ? 'manual' : isRecurringPayment ? 'recurring' : null
}

function isPaidTeam(team: Team, subscriptionType?: 'manual' | 'recurring') {
  const type = getPaidSubscriptionType(team)
  const status = getSubscriptionStatus(team)
  if (subscriptionType) {
    return type === subscriptionType && status !== 'unpaid'
  }
  return type && status !== 'unpaid'
}

function belongToAnyPaidTeams(user: CurrentUser) {
  return user.teams.some(team => isPaidTeam(team))
}

function isTeamAdmin(user: CurrentUser, teamId: string) {
  return user.teamMembers.find(m => m.teamId === teamId)?.role === 'admin'
}

function getPaymentRequiredTeams(user: CurrentUser) {
  return user.teams.reduce((prev: Team[], team) => {
    if (isPaidTeam(team)) return prev
    return [...prev, team]
  }, [])
}

// ------------------------------
// Billing
// ------------------------------

function useBilliableTeamMembers(teamId?: string) {
  return useQuery(
    getBilliableTeamMembersQueryKey(teamId),
    async () => {
      if (!teamId) throw new Error(`Use enabled field.`)
      const {
        data: members,
        error,
        count,
      } = await supabase
        .from<StoredTeamMember>('team_members')
        .select(`*`, {count: 'exact'})
        .eq('team_id', teamId)
        .neq('role', 'guest')
      if (error) throw error
      if (!count || !members) return null
      return {count, members}
    },
    {
      enabled: !!teamId,
    },
  )
}

const plans = {
  proQuarterly: '28a10e6c-1d4c-47c0-909d-86a0a85a12c4',
  proYearly: '4f700edc-1b28-49aa-b200-b4174637f6ec',
}

const TAX_RATIO = 0.1

type UseCalculatePricing = {
  memberCount: number
  additionalMemberCount: number
  pricePerMonth: number
  pricePerMonthPerUser: number
  price: number
  additionalPrice: number
  tax: number
  total: number
  minimumSeat: number
  pricePerMonthPerAdditionalUser: number
}

function calculateTeamPricing({
  memberCount,
  priceData,
}: {
  memberCount: number
  priceData: StoredPrice
}) {
  // Keep things simple
  if (
    priceData.interval !== 'month' ||
    !priceData.additional_unit_amount ||
    !priceData.minimum_unit
  ) {
    throw new Error(`Invalid price.`)
  }

  // Minimum # of seats e.g. 4
  const minimumSeat = priceData.minimum_unit

  // Monthly price for the selected plan e.g. 30,000
  const pricePerMonth = priceData.unit_amount

  // Recurring cycle
  const interval = priceData.interval_count

  // Monthly price per user e.g. 7,500
  const pricePerMonthPerUser = pricePerMonth / minimumSeat

  // Price to pay for the minimum users e.g. 90,000
  const price = pricePerMonth * interval

  // # of additional users in this team e.g. 4
  const additionalMemberCount =
    memberCount > minimumSeat ? memberCount - minimumSeat : 0

  // Monthly price per additional seat e.g. 5,000
  const pricePerMonthPerAdditionalUser = priceData.additional_unit_amount

  // Price to pay for the additional users e.g. 60,000
  const additionalPrice =
    pricePerMonthPerAdditionalUser * additionalMemberCount * interval

  // Tax e.g. 15,000
  const tax = (price + additionalPrice) * TAX_RATIO

  // Total e.g. 165,000
  const total = price + additionalPrice + tax

  return {
    memberCount,
    additionalMemberCount,
    pricePerMonth,
    pricePerMonthPerUser,
    price,
    additionalPrice,
    tax,
    total,
    minimumSeat,
    pricePerMonthPerAdditionalUser,
  }
}

function useCalculatePricing({
  price: priceData,
  teamId,
}: {
  price?: StoredPrice
  teamId?: string
}): UseCalculatePricing | null {
  const {data} = useBilliableTeamMembers(teamId)
  if (!data || !priceData) return null
  return calculateTeamPricing({priceData, memberCount: data.count})
}

function useUpdateSubscription() {
  const queryClient = useQueryClient()
  return useMutation(
    async ({
      token,
      teamId,
      event,
    }: {
      token: string
      teamId: string
      event: 'cancel' | 'resume'
    }) => {
      await client('/api/billing?type=subscription', {
        token,
        method: 'PATCH',
        data: {teamId, event},
      })
      return teamId
    },
    {
      onSettled: teamId => {
        if (teamId) {
          queryClient.invalidateQueries(getTeamQueryKey())
          queryClient.invalidateQueries(getTeamSubscriptionQueryKey(teamId))
        }
      },
    },
  )
}

export type {UseCalculatePricing}
export {
  belongToAnyPaidTeams,
  calculateTeamPricing,
  getPaidSubscriptionType,
  getPaymentRequiredTeams,
  getSubscriptionStatus,
  isPaidTeam,
  isTeamAdmin,
  plans,
  useCalculatePricing,
  useUpdateSubscription,
}
