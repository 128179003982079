import type {ResourceStatus, TeamResourceType, UsageType} from '@/types'

function getAppInvitesQueryKey() {
  return ['appInvites']
}

function getListSamplesBySampleIdQueryKey(sampleId?: string) {
  return ['listSamples', {sampleId}]
}
function getTeamListSamplesBySampleIdQueryKey(
  sampleId?: string,
  teamId?: string,
) {
  return ['listSamples', {sampleId, teamId}]
}
function getMyLists() {
  return ['myLists']
}
function getListCommentsByIdQueryKey(listId?: string) {
  return ['listComments', {listId}]
}
function getSampleCommentsByIdQueryKey(sampleId?: string) {
  return ['sampleComments', {sampleId}]
}

function getListByIdQueryKey(listId?: string) {
  return ['lists', {listId}]
}

function getListsByStatusQueryKey(data: {
  teamId?: string
  status?: ResourceStatus
}) {
  return ['lists', data]
}

function getInvoicesQueryKey(data: {teamId?: string}) {
  return ['invocies', data]
}

function getTeamFile(data: {teamId?: string}) {
  return ['files', data]
}

function getTeamActivity(data: {teamId?: string}) {
  return ['activities', data]
}

function getTeamResourceInvitesQueryKey(data: {
  type: TeamResourceType
  teamId: string
  listId?: string
}) {
  return ['invites', data]
}

function getFieldsQueryKey() {
  return ['fields']
}

function getSelectGroupsQueryKey() {
  return ['selectGroups']
}

// ------------------------------
//
// ------------------------------

function getInviteQueryKey() {
  return ['invite']
}

function getCurrentUserQueryKey() {
  return ['currentUser']
}

function getCurrentTeamQueryKey() {
  return ['currentTeam']
}

function getUserSessionsQueryKey() {
  return ['sessions']
}

function getTeamQueryKey(handle?: string) {
  return ['teams', {handle}]
}

function getTeamSubscriptionQueryKey(teamId: string) {
  return ['subscriptions', {teamId}]
}

function getUserListQueryKey(listId?: string) {
  return ['userLists', {id: listId}]
}

function getBilliableTeamMembersQueryKey(teamId?: string) {
  return ['billableMembers', {type: 'team', teamId}]
}

function getTeamMembersQueryKey(teamId?: string) {
  return ['members', {type: 'team', teamId}]
}

function getTeamAdminsQueryKey(teamId?: string) {
  return ['admins', {type: 'team', teamId}]
}

function getTeamInvitesQueryKey(teamId?: string) {
  return ['invites', {type: 'team', teamId}]
}

function getListInvitesQueryKey(listId?: string) {
  return ['invites', {type: 'list', listId}]
}

function getSampleQueryKey(id?: string) {
  return ['samples', {id}]
}

function getSamplesQueryKey() {
  return ['samples']
}

function getAdminSampleListQueryKey(data?: {
  query?: string
  selectIds?: string[]
  orderBy?: {id: string; desc: boolean}[]
}) {
  return ['adminSampleList', data]
}

function getAdminSampleQueryKey(id: string) {
  return ['adminSamples', id]
}

function getAdminSelectListQueryKey(data?: {
  query?: string
  selectGroupId?: string
  orderBy?: {id: string; desc: boolean}[]
}) {
  return ['adminSelectList', data]
}

function getAdminSelectQueryKey(id: string) {
  return ['adminSelects', id]
}

function getCurrentNotificationSettingQueryKey() {
  return ['currentNotificationSetting']
}

function getCurrentNotificationStatusQueryKey() {
  return ['currentNotificationStatus']
}

function getCurrentSessionQueryKey() {
  return ['currentSession']
}

function getActiveSessionsQueryKey() {
  return ['activeSessions']
}

function getOnboardingListQueryKey() {
  return ['onboardingList']
}

function getUserUsageQueryKey(type: UsageType, data?: any) {
  return ['userUsage', type, data]
}

export {
  getActiveSessionsQueryKey,
  getAdminSampleListQueryKey,
  getAdminSampleQueryKey,
  getAdminSelectListQueryKey,
  getAdminSelectQueryKey,
  getAppInvitesQueryKey,
  getBilliableTeamMembersQueryKey,
  getCurrentNotificationSettingQueryKey,
  getCurrentNotificationStatusQueryKey,
  getCurrentSessionQueryKey,
  getCurrentTeamQueryKey,
  getCurrentUserQueryKey,
  getFieldsQueryKey,
  getInviteQueryKey,
  getInvoicesQueryKey,
  getListByIdQueryKey,
  getListCommentsByIdQueryKey,
  getListInvitesQueryKey,
  getListSamplesBySampleIdQueryKey,
  getListsByStatusQueryKey,
  getMyLists,
  getOnboardingListQueryKey,
  getSampleCommentsByIdQueryKey,
  getSampleQueryKey,
  getSamplesQueryKey,
  getSelectGroupsQueryKey,
  getTeamActivity,
  getTeamAdminsQueryKey,
  getTeamFile,
  getTeamInvitesQueryKey,
  getTeamListSamplesBySampleIdQueryKey,
  getTeamMembersQueryKey,
  getTeamQueryKey,
  getTeamResourceInvitesQueryKey,
  getTeamSubscriptionQueryKey,
  getUserListQueryKey,
  getUserSessionsQueryKey,
  getUserUsageQueryKey,
}
